export class ResourceLoading {
    creative_template_id: boolean = false;
    creative_size_id: boolean = false;
    creative_type_id: boolean = false;
    advertiser_id: boolean = false;
    category_id: boolean = false;
    video_duration: boolean = false;
    companion_size_id: boolean = false;
    primary_asset_id: boolean = false;
    secondary_asset_id: boolean = false;
    creative_rule_id: boolean = false;
    audio_duration: boolean = false;
    mime_type_id: boolean = false;
    video_mime_types: boolean = false;
    expandable_type_id: boolean = false;
    creative_attributes: boolean = false;
    in_banner_video_id: boolean = false;
    audio_mime_types: boolean = false;
    video_api_id: boolean = false;
    video_bit_rates: boolean = false;
    audio_bit_rates: boolean = false;
    vendors: boolean = false;
    thumbnail_id: boolean = false;
    addons: boolean = false;

    constructor() {

    }

    setLoading(fielName: string, loading: boolean) {
        this[fielName] = loading;
    }
}